<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/" class="navClass">Home</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Test</li>
      </ol>
    </nav>
    <HelloWorld msg="Welcome to Your Vue.js App" />

    <select v-model="basedata.selected" class="form-control" id="sel1">
      <option v-for="(n, index) in basedata.MoonList" :key="index">
        {{ n.id }}
      </option>
    </select>
    <button @click="ShowInfo">ShowInfo</button>
    <p><button @click="DoLineAccess">Line Login</button></p>
    <p><button @click="LinePost">Line Post</button></p>
    <p><input type="text" id="tbxToken" v-model="basedata.token" /></p>
    <p><button @click="LineBack">Line back Prarm</button></p>

    <p><button @click="LinePost2">Line Post 2</button></p>
    <p><button @click="VerifyToken">Verify Token</button></p>

    <p><button @click="GetUserName">Get User Name</button></p>
    <p><img style="width: 300px" :src="basedata.pic_url" /></p>
    <p>MyTitle:{{ myTitle }}</p>
  </div>
</template>

<script>
import { ref, onMounted, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import HelloWorld from "../components/HelloWorld.vue";
import { GetMoonList, LineGetToken } from "../../api.js";
// import axios from "axios";

export default {
  name: "Test",
  components: {
    HelloWorld,
  },
  setup() {
    const route = useRoute();
    console.log("route.query==>", route.query.code);
    const userCode = ref(route.params);

    let errorStatus = ref("");
    let myTitle = process.env.VUE_APP_Title;
    const basedata = reactive({
      MoonList: [],
      selected: "",
      token: "",
      pic_url: "",
    });

    watch(
      () => userCode,
      (vNew, vOld) => {
        console.log("route.params-vNew", vNew);
        console.log("route.params-vOld", vOld);
      }
    );

    function LineBack() {
      // const route = useRoute();

      console.log("route.query==>", route.query.code);
    }

    function DoLineAccess() {
      let url =
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=" +
        process.env.VUE_APP_Channel_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_Callback_URL +
        "&state=12345abcde&scope=openid%20profile&nonce=09876xyz";
      //window.open(url);
      window.location = url;
    }

    function LinePost() {
      let ContentType = "application/x-www-form-urlencoded;";

      // let Content = `grant_type=authorization_code
      //   &code=${route.query.code}
      //   &redirect_uri=http://localhost:8000/Test
      //   &client_id=1655255104
      //   &client_secret=ab9d0e130b3794343113601d5ad88dd6`;

      // let Content = JSON.stringify({
      //   grant_type: encodeURI("authorization_code"),
      //   code: encodeURI(route.query.code),
      //   redirect_uri: encodeURI("http://localhost:8000/Test"),
      //   client_id: encodeURI("1655255104"),
      //   client_secret: encodeURI("ab9d0e130b3794343113601d5ad88dd6"),
      // });

      const Content = new URLSearchParams();
      Content.append("grant_type", "authorization_code");
      Content.append("code", route.query.code);
      Content.append("redirect_uri", process.env.VUE_APP_Callback_URL);
      Content.append("client_id", process.env.VUE_APP_Channel_ID);
      Content.append("client_secret", process.env.VUE_APP_Channel_secret);

      console.log("Content:", Content);

      const uri = "https://api.line.me/oauth2/v2.1/token";
      fetch(uri, {
        method: "POST",
        headers: {
          "Content-Type": ContentType,
        },
        body: Content,
      })
        .then((res) => {
          console.log("res type:", res);

          return res.json(); // 使用 json() 可以得到 json 物件
        })
        .then((result) => {
          console.log("result type:", result); //
          console.log("Token:", result.access_token);

          //Save Token
          localStorage.setItem("LineToken", result.access_token);
          localStorage.setItem("User_ID", result.id_token);
        });
    }

    function VerifyToken() {
      var vToken = localStorage.getItem("LineToken");
      console.log("Get line Token From local Storage", vToken);

      let vStatus = "";
      const uri = "https://api.line.me/oauth2/v2.1/verify?access_token=";
      fetch(uri + vToken, {
        method: "GET",
      })
        .then((res) => {
          console.log("res type:", res);
          vStatus = res.status;
          return res.json(); // 使用 json() 可以得到 json 物件
        })
        .then((result) => {
          console.log("result type:", result); //
          console.log("Final Status:", vStatus);
          // vStatus=400  錯誤
          // vStatus=200  正確
          if (vStatus == "200") {
            alert("授權成功");
          } else {
            alert("授權失敗");
          }
        });
    }

    function GetUserName() {
      var vUserID = localStorage.getItem("User_ID");
      console.log("User ID Token", vUserID);
      const Content = new URLSearchParams();
      Content.append("id_token", localStorage.getItem("User_ID"));
      Content.append("client_id", process.env.VUE_APP_Channel_ID);

      const uri = "https://api.line.me/oauth2/v2.1/verify";
      fetch(uri, {
        method: "POST",
        body: Content,
      })
        .then((res) => {
          console.log("res type:", res);

          return res.json(); // 使用 json() 可以得到 json 物件
        })
        .then((result) => {
          console.log("result type:", result); //
          console.log("Token:", result.access_token);

          //存 local storage
          localStorage.setItem("user_name", result.name);
          localStorage.setItem("user_subid", result.sub);
          localStorage.setItem("user_picture", result.picture);

          //IMG
          basedata.pic_url = result.picture;

          alert(`${result.name} 您好!`);
        });
    }

    function LinePost2() {
      //LineGetToken
      let data = JSON.stringify({
        grant_type: encodeURI("authorization_code"),
        code: encodeURI(route.query.code),
        redirect_uri: encodeURI(process.env.VUE_APP_Callback_URL),
        client_id: encodeURI(process.env.VUE_APP_Channel_ID),
        client_secret: encodeURI(process.env.VUE_APP_Channel_secret),
      });
      LineGetToken(data)
        .then(function (response) {
          console.log("GetListData result:", response.data);
          basedata.MoonList = response.data;
        })
        .catch(function (error) {
          if (!error.response) {
            // network error
            errorStatus = "Error: Network Error";
          } else {
            errorStatus = error.response.data.message;
          }
        });
    }

    function GetListData() {
      //const results = {};
      console.log("Do GetListData()");
      GetMoonList()
        .then(function (response) {
          console.log("GetListData result:", response.data);
          basedata.MoonList = response.data;
        })
        .catch(function (error) {
          if (!error.response) {
            // network error
            errorStatus = "Error: Network Error";
          } else {
            errorStatus = error.response.data.message;
          }
        });
    }

    function ShowInfo() {
      let index = basedata.MoonList.findIndex((x) => x.id == basedata.selected);
      console.log("now index:", index);
      let vOne = basedata.MoonList[index];
      console.log(vOne.source);
      console.log(vOne.url);
    }

    onMounted(() => {
      GetListData();
      basedata.pic_url = localStorage.getItem("user_picture");
    });

    return {
      errorStatus,
      GetListData,
      basedata,
      ShowInfo,
      LinePost,
      DoLineAccess,
      LineBack,
      LinePost2,
      VerifyToken,
      GetUserName,
      myTitle,
    };
  },
};
</script>

<style>
</style>