<template>
  <div class="hello">
    <h5>{{ msg }}</h5>

    <h4>Installed CLI Plugins</h4>
    <button @click="GetTeacherData">Test</button>
    <span>{{ errorStatus }}</span>
    <ul v-for="(n, index) in basedata.list" :key="index">
      <li>{{ n.teacherName }}</li>
    </ul>
    <hr />
    <div>Bloger</div>
    <button @click="GetBlogerData">Test</button>
    <ul v-for="(n, index) in basedata.blogerlist" :key="index">
      <li>{{ n.bloggerTitle }}</li>
    </ul>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { GetTeacherList, GetBlogerList } from "../../api.js";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  setup() {
    console.log("process.env:", process.env.VUE_APP_API_ENDPOINT);
    let errorStatus = ref("");
    const basedata = reactive({
      list: [],
      blogerlist: [],
    });

    function GetTeacherData() {
      //const results = {};
      console.log("Do GetTeacherData()");
      GetTeacherList()
        .then(function (response) {
          console.log(response.data);
          basedata.list = response.data;
        })
        .catch(function (error) {
          if (!error.response) {
            // network error
            errorStatus = "Error: Network Error";
          } else {
            errorStatus = error.response.data.message;
          }
        });
    }

    function GetBlogerData() {
      //const results = {};
      console.log("Do GetBlogerData()");
      GetBlogerList()
        .then(function (response) {
          console.log("GetBlogerList() Result:", response.data);
          basedata.blogerlist = response.data;
        })
        .catch(function (error) {
          if (!error.response) {
            // network error
            errorStatus = "Error: Network Error";
          } else {
            errorStatus = error.response.data.message;
          }
        });
    }

    onMounted(() => {
      GetBlogerData();
    });

    return { GetTeacherData, errorStatus, basedata, GetBlogerData };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
